import {Component, EventEmitter, Input, KeyValueDiffer, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api';
import {AuthorisationService} from '../../../subjects/authorisation-behaviour-subject';
import {FileType} from '../models/enumerations';
import {ForwardingFile, ForwardingTransportDocument} from '../models/ForwardingFile';
import {ForwardingFileService} from '../services/forwarding-file.service';

@Component({
  selector: 'digi-forwarding-dashboard',
  templateUrl: './forwarding-dashboard.component.html',
  styleUrls: ['./forwarding-dashboard.component.scss']
})
export class ForwardingDashboardComponent implements OnInit {
  @Input() transportMethod: string;
  rcgFiles: ForwardingFile[];
  totalRecords: any;
  loading: boolean;
  cols: any[];
  indicator: FileType;
  mawbNo: string;
  mawbFilter: string;
  private branchCode: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authorisationService: AuthorisationService,
    private forwardingFileService: ForwardingFileService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.indicator = this.route.snapshot.paramMap.get('indicator') as FileType;
        this.loadRcgFiles();
      }
    });
  }

  ngOnInit() {
    this.cols = [
      {field: 'ediMessage.fileNumber', header: 'File Number', width: '8%'},
      {field: 'ediMessage.documentReferenceNumber', header: 'Reference', width: '10%'},
      {field: 'ediMessage.dateEdiSubmitted', header: 'Sent On', width: '6%'},
      {field: '', header: 'MDN', width: '3%'},
      {field: '', header: 'CONTRL Recv On', width: '6%'},
      {field: '', header: 'CONTRL', width: '3%'},
      {field: '', header: 'CUSRES Recv On', width: '6%'},
      {field: '', header: 'CUSRES', width: '12%'},
    ];
  }

  onLazyLoad(event: LazyLoadEvent) {
    this.authorisationService.getLoggedInUser().subscribe(user => {
      if (user) {
        this.loading = true;
        const pageNumber = event.first / event.rows;
        if (user && user.attributes && user.attributes['branch']) {
          this.branchCode = user.attributes['branch'][0];
        }

        this.forwardingFileService.getAllRcgFiles(pageNumber, event.rows)
          .subscribe(data => {
            this.rcgFiles = data.content;
            this.totalRecords = data.totalElements;
            this.loading = false;
          }, err => {
            console.error('Error occurred loading RCG files:', err);
            this.loading = false;
          });
      }
    });
  }

  loadRcgFiles() {
    this.mawbFilter = null;
    this.onLazyLoad({first: 0, rows: 10});
  }

  setBackgroundColor(isInvalid: boolean): string {
    if (isInvalid) {
      return "red";
    } else {
      return "transparent";
    }
  }

  setFileNumberColor(isInvalid: boolean): string {
    if (isInvalid) {
      return "white";
    } else {
      return "#607D8B";
    }
  }

  loadSearchedMawbFile() {
    if (this.mawbFilter && this.mawbFilter !== '') {
      this.loading = true;
      this.forwardingFileService.findRcgFilesByMawbNo(this.mawbFilter)
        .subscribe(data => {
          this.rcgFiles = data;
          this.totalRecords = data.length;
          this.loading = false;
        }, err => {
          console.error('Error occurred loading RCG file by MawbNo:', err);
          this.loading = false;
        });
    }
  }

  controlStatus(file: ForwardingFile) {
    if (file) {
      if (file.contrl) {
        if (file.contrl.messageStatus === 'ACKNOWLEDGED' && file.contrl.interchangeStatus === 'ACKNOWLEDGED') {
          return true;
        }
      }
    }
    return false;
  }

  mdnResponseStatus(file: ForwardingFile) {
    if (file) {
      if (file.mdnResponse) {
        if (!file.mdnResponse.errorMessage) {
          return true;
        }
      }
    }
    return false;
  }

  loadForwardingFile(fileNumber: string) {
    this.router.navigate(['/forwarding/air', 'manifests', fileNumber]);
  }


  getStatus(ediMessage: any) {
    if (ediMessage && ediMessage.cusres) {
      if (ediMessage.cusres.customsStatus === 'DETAIN_OTHER_OGA_OTHER_GOVERNMENT_AGENCY' || ediMessage.cusres.customsStatus === 'CUSTOMS_STOP_AWAITING_OGA_OUTCOME' && ediMessage.cusres.customsStatusError) {
        return ediMessage.cusres.customsStatusError;
      } else {
        return ediMessage.cusres.customsStatus;
      }
    }
  }
}
