import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config.module';

@Injectable({
  providedIn: 'root'
})
export class PaymentsReportService {

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) { }

  generateImportsReport(from, to, customsOffice, importers: string[]): Observable<any> {
    return this.http
      .post(`${this.config.filesApiEndpoint}api/financialReports/importsDeferredPayments?from=${from}&to=${to}&customsOfficeCode=${customsOffice}`,
        importers);
  }

  generateExBondReport(from, to, customsOffice, importers: string[]): Observable<any> {
      return this.http
        .post(`${this.config.filesApiEndpoint}api/financialReports/exBondDeferredPayments?from=${from}&to=${to}&customsOfficeCode=${customsOffice}`,
          importers);
    }

  generateVatReport(from, to, importerCode: string, customsOffice, sarsPrn, receiptDate, receiptRef: string): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/financialReports/vatPayments?from=${from}&to=${to}&importerCode=${importerCode}&customsOfficeCode=${customsOffice}&sarsPrn=${sarsPrn}&receiptDate=${receiptDate}&receiptRef=${receiptRef}`);
  }

  generateRcgClearanceReport(mawbNo: string): Observable<any> {
    return this.http.get(`${this.config.filesApiEndpoint}api/financialReports/rcgClearance?mawbNo=${mawbNo}`);
  }
}
