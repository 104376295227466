import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config.module';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../app.config.module";
var PaymentsReportService = /** @class */ (function () {
    function PaymentsReportService(http, config) {
        this.http = http;
        this.config = config;
    }
    PaymentsReportService.prototype.generateImportsReport = function (from, to, customsOffice, importers) {
        return this.http
            .post(this.config.filesApiEndpoint + "api/financialReports/importsDeferredPayments?from=" + from + "&to=" + to + "&customsOfficeCode=" + customsOffice, importers);
    };
    PaymentsReportService.prototype.generateExBondReport = function (from, to, customsOffice, importers) {
        return this.http
            .post(this.config.filesApiEndpoint + "api/financialReports/exBondDeferredPayments?from=" + from + "&to=" + to + "&customsOfficeCode=" + customsOffice, importers);
    };
    PaymentsReportService.prototype.generateVatReport = function (from, to, importerCode, customsOffice, sarsPrn, receiptDate, receiptRef) {
        return this.http.get(this.config.filesApiEndpoint + "api/financialReports/vatPayments?from=" + from + "&to=" + to + "&importerCode=" + importerCode + "&customsOfficeCode=" + customsOffice + "&sarsPrn=" + sarsPrn + "&receiptDate=" + receiptDate + "&receiptRef=" + receiptRef);
    };
    PaymentsReportService.prototype.generateRcgClearanceReport = function (mawbNo) {
        return this.http.get(this.config.filesApiEndpoint + "api/financialReports/rcgClearance?mawbNo=" + mawbNo);
    };
    PaymentsReportService.ngInjectableDef = i0.defineInjectable({ factory: function PaymentsReportService_Factory() { return new PaymentsReportService(i0.inject(i1.HttpClient), i0.inject(i2.APP_CONFIG)); }, token: PaymentsReportService, providedIn: "root" });
    return PaymentsReportService;
}());
export { PaymentsReportService };
