<div class="card card-w-title">
  <h1 style="text-align: center">SARS Messages</h1>
  <div class="ui-g">
    <div class="ui-g-11">

      <div class="ui-inputgroup">
        <input type="text" pInputText placeholder="Mawb No."
               [(ngModel)]="mawbFilter">
        <span class="fa fa-times ui-inputgroup-addon"
              *ngIf="mawbFilter && mawbFilter.length > 1"
              (click)="loadRcgFiles()"></span>
        <button pButton label="Search MAWB" style="margin: 0 10px 0 10px;" (click)="loadSearchedMawbFile()"></button>
      </div>
    </div>
  </div>
  <p-table [value]="rcgFiles" [paginator]="true" [rows]="10" [lazy]="true" [lazyLoadOnInit]="true"
           [loading]="loading"
           (onLazyLoad)="onLazyLoad($event)"
           [totalRecords]="totalRecords"
           [columns]="cols"
           [responsive]="true">
    <ng-template pTemplate="colgroup" let-columns>
      <colgroup>
        <col *ngFor="let col of columns" [style.width]="col.width">
      </colgroup>
    </ng-template>
    <ng-template pTemplate="header" let-columns let-ediMessage>
      <tr>
        <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
          {{col.header}}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-ediMessage>
      <tr>
        <td [ngStyle]="{
            'background-color': setBackgroundColor(ediMessage.missingClearingData)
          }"><a (click)="loadForwardingFile(ediMessage?.fileNumber)" [ngStyle]="{
            'color': setFileNumberColor(ediMessage.missingClearingData)
          }">{{ediMessage?.fileNumber}}</a></td>
        <td class="truncate wrap" [ngStyle]="{
            'background-color': setBackgroundColor(ediMessage.missingClearingData)
          }">{{ediMessage?.documentMessageNumber}}</td>
        <td [ngStyle]="{
            'background-color': setBackgroundColor(ediMessage.missingClearingData)
          }">{{ediMessage?.dateEdiSubmitted}}</td>
        <td style="text-align: center;" [ngStyle]="{
            'background-color': setBackgroundColor(ediMessage.missingClearingData)
          }">
          <i class="pi pi-check" [ngClass]="{'pi': true, 'pi-check-circle': mdnResponseStatus(ediMessage), 'pi-times-circle': !mdnResponseStatus(ediMessage)}">
          </i>
        </td>
        <td [ngStyle]="{
            'background-color': setBackgroundColor(ediMessage.missingClearingData)
          }">{{ediMessage?.contrl?.receivedDate}}</td>
        <td style="text-align: center;" [ngStyle]="{
            'background-color': setBackgroundColor(ediMessage.missingClearingData)
          }">
          <i class="pi pi-check" [ngClass]="{'pi': true, 'pi-check-circle': controlStatus(ediMessage), 'pi-times-circle': !controlStatus(ediMessage)}">
          </i>
        </td>
        <td [ngStyle]="{
            'background-color': setBackgroundColor(ediMessage.missingClearingData)
          }">{{ediMessage?.cusres?.receivedDate}}</td>
        <td [ngStyle]="{
            'background-color': setBackgroundColor(ediMessage.missingClearingData)
          }">{{ getStatus(ediMessage) | replaceUnderScores}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
