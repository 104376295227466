import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {MenuItem, ScrollPanel} from 'primeng/primeng';
import {AppComponent} from './app.component';
import {BreadcrumbService} from './breadcrumb.service';

@Component({
  selector: 'digi-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit, AfterViewInit {

  @Input() reset: boolean;

  model: MenuItem[];
  items: MenuItem[];
  item: MenuItem;
  home: MenuItem;

  @ViewChild('scrollPanel') layoutMenuScrollerViewChild: ScrollPanel;

  constructor(public app: AppComponent, private router: Router,
              public breadcrumbService: BreadcrumbService) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.layoutMenuScrollerViewChild.moveBar();
    }, 100);
  }

  ngOnInit() {
    this.model = [
      {
        label: 'Home', icon: 'home', routerLink: ['/'], command: (event) => {
          const innerItems = [];
          this.items = [];
          this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
          this.items.push(this.home);
          this.breadcrumbService.setItems(this.items);
        }
      },
      {
        label: 'SARS Dashboard',
        items: [
          {
            label: 'Imports / File Enquiry',
            icon: 'file_download',
            command: (event) => {
              this.router.navigate(['/common/sars-dashboard/imports']);
            },

          },
          {
            label: 'Exports / File Enquiry',
            icon: 'file_upload',
            command: (event) => {
              this.router.navigate(['/common/sars-dashboard/exports']);
            }
          },
          {
            label: 'Ex Bond',
            icon: 'https',
            command: (event) => {
              this.router.navigate(['/common/sars-dashboard/exbond']);
            }
          },
          {
            label: 'Forwarding',
            icon: 'https',
            command: (event) => {
              this.router.navigate(['/common/forwarding-dashboard/forwarding']);
            }
          }
        ],
        icon: 'list'
      },
      {
        label: 'Import', icon: 'file_download',
        items: [
          {
            label: 'Air', icon: 'airplanemode_active', command: (event) => {
              this.router.navigate(['/air/register', 'nq']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-airplanemode-active', routerLink: '/air/register'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Sea', icon: 'directions_boat',
            items: [
              {
                label: 'File',
                command: (event) => {
                  this.router.navigate(['/sea/register', 'nq']);
                  const innerItems = [];
                  this.items = [];
                  this.item = <MenuItem>{icon: 'ui-icon-directions-boat', routerLink: '/sea/register'};
                  innerItems.push(this.item);
                  this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                  this.items.push(this.home);
                  this.breadcrumbService.setItems(this.items);
                },
              },
              {
                label: 'Ships Agent',
                command: (event) => {
                  this.router.navigate(['/sea/ships-agent']);
                  const innerItems = [];
                  this.items = [];
                  this.item = <MenuItem>{icon: 'description', routerLink: '/sea/ships-agent'};
                  innerItems.push(this.item);
                  this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                  this.items.push(this.home);
                  this.breadcrumbService.setItems(this.items);
                }
              }
            ]
          },
          {
            label: 'Road', icon: 'directions_bus', command: (event) => {
              this.router.navigate(['/road/register']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-directions-bus', routerLink: '/road/register'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Rail', icon: 'directions_railway', command: (event) => {
            }
          },
          {
            label: 'Ex Bond', icon: 'https', command: (event) => {
              this.router.navigate(['/exbond/register']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-https', routerLink: '/exbond/register'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Reports', icon: 'description',
            items: [
              {
                label: 'Bond Store Report',
                command: (event) => {
                  this.router.navigate(['/common/reports/import-bond-store-reports']);
                  const innerItems = [];
                  this.items = [];
                  this.item = <MenuItem>{icon: 'ui-icon-attach-money', routerLink: '/common/import/import-bond-store-reports'};
                  innerItems.push(this.item);
                  this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                  this.items.push(this.home);
                  this.breadcrumbService.setItems(this.items);
                }
              }
            ]
          }
        ]
      },
      {
        label: 'Export', icon: 'file_upload',
        items: [
          {
            label: 'Air', icon: 'airplanemode_active', command: (event) => {
              this.router.navigate(['export/air/register']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-airplanemode-active', routerLink: 'export/air/register'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Sea', icon: 'directions_boat',
            items: [
              {
                label: 'File',
                command: (event) => {
                  this.router.navigate(['/export/sea/register']);
                  const innerItems = [];
                  this.items = [];
                  this.item = <MenuItem>{icon: 'ui-icon-directions-boat', routerLink: 'export/sea/register'};
                  innerItems.push(this.item);
                  this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                  this.items.push(this.home);
                  this.breadcrumbService.setItems(this.items);
                },
              },
              {
                label: 'Ships Agent',
                command: (event) => {
                  this.router.navigate(['/export/sea/ships-agent']);
                  const innerItems = [];
                  this.items = [];
                  this.item = <MenuItem>{icon: 'description', routerLink: '/export/sea/ships-agent'};
                  innerItems.push(this.item);
                  this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                  this.items.push(this.home);
                  this.breadcrumbService.setItems(this.items);
                }
              }
            ]
          },
          {
            label: 'Road', icon: 'directions_bus',
            items: [
              {
                label: 'File',
                command: (event) => {
                  this.router.navigate(['export/road/register']);
                  const innerItems = [];
                  this.items = [];
                  this.item = <MenuItem>{icon: 'ui-icon-directions-bus', routerLink: '/road/register'};
                  innerItems.push(this.item);
                  this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                  this.items.push(this.home);
                  this.breadcrumbService.setItems(this.items);
                },
              },
              {
                label: 'Manifests',
                command: (event) => {
                  this.router.navigate(['/export/road/manifest']);
                  const innerItems = [];
                  this.items = [];
                  this.item = <MenuItem>{icon: 'description', routerLink: '/export/road/manifest'};
                  innerItems.push(this.item);
                  this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                  this.items.push(this.home);
                  this.breadcrumbService.setItems(this.items);
                }
              }
            ]
          },
          {
            label: 'Rail', icon: 'directions_railway', command: (event) => {
              this.router.navigate(['/export/rail/register']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-directions-railway-active', routerLink: '/export/rail/register'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Reports', icon: 'description',
            items: [
              {
                label: 'Registration Report',
                command: (event) => {
                  this.router.navigate(['/common/reports/export-registration-reports']);
                  const innerItems = [];
                  this.items = [];
                  this.item = <MenuItem>{icon: 'ui-icon-attach-money', routerLink: '/common/export/export-registration-reports'};
                  innerItems.push(this.item);
                  this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                  this.items.push(this.home);
                  this.breadcrumbService.setItems(this.items);
                }
              }
            ]
          }
        ]
      },
      {
        label: 'Forwarding / RCG', icon: 'forward',
        items: [
          {
            label: 'Air', icon: 'airplanemode_active', command: (event) => {
              this.router.navigate(['/forwarding/air/manifests']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-airplanemode-active', routerLink: '/forwarding/air/manifests'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Sea', icon: 'directions_boat', command: (event) => {
              this.router.navigate(['/forwarding/sea/manifests']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-direction-boat', routerLink: '/forwarding/sea/manifests'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          }
          // ,
          // {
          //   label: 'Tracking', icon: 'gps_fixed', command: (event) => {
          //     this.router.navigate(['/parcel-tracking']);
          //     const innerItems = [];
          //     this.items = [];
          //     this.item = <MenuItem>{icon: 'gps_fixed', routerLink: '/parcel-tracking'};
          //     innerItems.push(this.item);
          //     this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
          //     this.items.push(this.home);
          //     this.breadcrumbService.setItems(this.items);
          //   }
          // }
        ]
      },
      {
        label: 'Quotation', icon: 'description',
        items: [
          {
            label: 'Import', icon: 'file_download',
            items: [
              {
                label: 'Air', icon: 'airplanemode_active', command: (event) => {
                  this.router.navigate(['/air/register', 'iq']);
                  const innerItems = [];
                  this.items = [];
                  this.item = <MenuItem>{icon: 'ui-icon-airplanemode-active', routerLink: '/air/register'}; //Todo : need to edit this link (maybe fixes issue where naving from nq -> iq doesnt behave correctly) ?
                  innerItems.push(this.item);
                  this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                  this.items.push(this.home);
                  this.breadcrumbService.setItems(this.items);
                }
              },
              {
                label: 'Sea', icon: 'directions_boat',
                items: [
                  {
                    label: 'File',
                    command: (event) => {
                      this.router.navigate(['/sea/register', 'iq']);
                      const innerItems = [];
                      this.items = [];
                      this.item = <MenuItem>{icon: 'ui-icon-directions-boat', routerLink: '/sea/register'};
                      innerItems.push(this.item);
                      this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
                      this.items.push(this.home);
                      this.breadcrumbService.setItems(this.items);
                    }
                  }
                ]
              },
            ]
          }
//           {
//             label: 'Export', icon: 'file_upload',
//             items: [
//               {
//                 label: 'Air', icon: 'airplanemode_active', command: (event) => {
//                   this.router.navigate(['export/air/register']);
//                   const innerItems = [];
//                   this.items = [];
//                   this.item = <MenuItem>{icon: 'ui-icon-airplanemode-active', routerLink: 'export/air/register'};
//                   innerItems.push(this.item);
//                   this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
//                   this.items.push(this.home);
//                   this.breadcrumbService.setItems(this.items);
//                 }
//               },
//               {
//                 label: 'Sea', icon: 'directions_boat',
//                 items: [
//                   {
//                     label: 'File',
//                     command: (event) => {
//                       this.router.navigate(['/export/sea/register']);
//                       const innerItems = [];
//                       this.items = [];
//                       this.item = <MenuItem>{icon: 'ui-icon-directions-boat', routerLink: 'export/sea/register'};
//                       innerItems.push(this.item);
//                       this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
//                       this.items.push(this.home);
//                       this.breadcrumbService.setItems(this.items);
//                     },
//                   }
//                 ]
//               }
//             ]
//           }
        ]
      },
      {
        label: 'Reports',
        icon: 'description',
        items: [
          {
            label: 'Deferred Payments',
            icon: 'attach_money',
            command: (event) => {
              this.router.navigate(['/common/reports/deferred-payments']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-attach-money', routerLink: '/common/reports/deferred-payments'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'VAT Payments',
            icon: 'attach_money',
            command: (event) => {
              this.router.navigate(['/common/reports/vat-payments']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-attach-money', routerLink: '/common/reports/vat-payments'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Reprint Invoices',
            icon: 'attach_money',
            command: (event) => {
              this.router.navigate(['/common/reports/reprint-invoices']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-attach-money', routerLink: '/common/reports/reprint-invoices'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Invoice Register',
            icon: 'attach_money',
            command: (event) => {
              this.router.navigate(['/common/reports/invoice-register']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-attach-money', routerLink: '/common/reports/invoice-register'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'RCG Clearance',
            icon: 'attach_money',
            command: (event) => {
              this.router.navigate(['/common/reports/rcg-clearance']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-attach-money', routerLink: '/common/reports/rcg-clearance'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'VOC report',
            icon: 'attach_money',
            command: (event) => {
              this.router.navigate(['/common/reports/voc-report']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-attach-money', routerLink: '/common/reports/voc-report'};
            }
          },
          {
            label: 'DA490',
            icon: 'attach_money',
            command: (event) => {
              this.router.navigate(['/common/reports/da490']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-attach-money', routerLink: '/common/reports/da490'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'DA65',
            icon: 'attach_money',
            command: (event) => {
              this.router.navigate(['/common/reports/da65']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-attach-money', routerLink: '/common/reports/da65'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
        ]
      },
        {
        label: 'Configuration', icon: 'settings',
        items: [
          {
            label: 'Business Entity', icon: 'people', command: (event) => {
              this.router.navigate(['/common/business-entity']);
            },
          },
          {
            label: 'Tariff Maintenance', icon: 'tune', command: (event) => {
              this.router.navigate(['/common/tariff-mantainance/tariff']);
            }
          },
          {
            label: 'Exchange Rate', icon: 'trending_up', command: (event) => {
              this.router.navigate(['/common/exchange-rates']);
            }
          },
          {
            label: 'Permits', icon: 'description', command: (event) => {
              this.router.navigate(['/common/permits']);
            }
          },
          {
            label: 'AWB Pool', icon: 'collections', command: (event) => {
              this.router.navigate(['/common/awb-pool']);
            },
          },
          {
            label: 'Parts Maintenance', icon: 'tune',
            items: [
              {
                label: 'Imports',
                icon: 'file_download',
                command: (event) => {
                  this.router.navigate(['/common/parts-maintenance/imports']);
                },
              },
              {
                label: 'Exports',
                icon: 'file_upload',
                command: (event) => {
                  this.router.navigate(['/common/parts-maintenance/exports']);
                }
              }
            ]
          },
          {
            label: 'Crew Maintenance', icon: 'person', command: (event) => {
              this.router.navigate(['/common/crew-maintenance']);
            },
          },
          {
            label: 'Diagnostics', icon: 'tune', command: (event) => {
              this.router.navigate(['/common/diagnostics']);
            }
          },
          {
            label: 'Company', icon: 'business', command: (event) => {
              this.router.navigate(['/common/company']);
            }
          },
        ]
      },
      {
        label: 'Utilities',
        icon: 'info_outline',
        items: [
          {
            label: 'CPC Enquiry',
            command: (event) => {
              this.router.navigate(['/common/enquiries/cpc-enquiry']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-info', routerLink: '/common/enquiries/cpc-enquiry'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Tariff Code Enquiry',
            command: (event) => {
              this.router.navigate(['/common/enquiries/tariff-code-enquiry']);
              const innerItems = [];
              this.items = [];
              this.item = <MenuItem>{icon: 'ui-icon-info', routerLink: '/common/enquiries/tariff-code-enquiry'};
              innerItems.push(this.item);
              this.home = <MenuItem>{icon: 'ui-icon-home', routerLink: '/', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Explanatory Notes',
            command: (event) => {
              window.open('https://www.sars.gov.za/?s=explanatory+notes', '_blank');
              const innerItems = [];
              this.items = [];
              this.home = <MenuItem>{icon: 'ui-icon-home', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Air Track Trace',
            command: (event) => {
              window.open('https://www.track-trace.com/aircargo', '_blank');
              const innerItems = [];
              this.items = [];
              this.home = <MenuItem>{icon: 'ui-icon-home', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Navis Cont. Enquiry',
            command: (event) => {
              window.open('http://41.160.4.49:9080/apex/cap.zul', '_blank');
              const innerItems = [];
              this.items = [];
              this.home = <MenuItem>{icon: 'ui-icon-home', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
          {
            label: 'Prohibited Goods',
            command: (event) => {
              // tslint:disable-next-line:max-line-length
              window.open('https://www.sars.gov.za/wp-content/uploads/Docs/ProhibitedGoods/Prohibited-and-Restricted-Imports-and-Exports-list-25-Jan-2024.xlsx', '_blank');
              const innerItems = [];
              this.items = [];
              this.home = <MenuItem>{icon: 'ui-icon-home', items: innerItems};
              this.items.push(this.home);
              this.breadcrumbService.setItems(this.items);
            }
          },
        ]
      }
    ];
  }


  changeTheme(theme) {
    const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
    themeLink.href = 'assets/theme/theme-' + theme + '.css';
  }

  changeLayout(theme) {
    const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
  }
}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
    <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
      <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass">
        <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" *ngIf="!child.routerLink"
           [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
           (mouseenter)="onMouseEnter(i)" class="ripplelink">
          <i class="material-icons">{{child.icon}}</i>
          <span class="menuitem-text">{{child.label}}</span>
          <i class="material-icons layout-submenu-toggler" *ngIf="child.items">keyboard_arrow_down</i>
          <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>

        <a (click)="itemClick($event,child,i)" *ngIf="child.routerLink"
           [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
           [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target"
           (mouseenter)="onMouseEnter(i)" class="ripplelink">
          <i class="material-icons">{{child.icon}}</i>
          <span class="menuitem-text">{{child.label}}</span>
          <i class="material-icons layout-submenu-toggler" *ngIf="child.items">>keyboard_arrow_down</i>
          <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>
        <ul app-submenu [item]="child" *ngIf="child.items && isActive(i)" [visible]="isActive(i)" [reset]="reset"
            [parentActive]="isActive(i)" [@children]="(app.isHorizontal())&&root ? isActive(i) ?
                    'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
      </li>
    </ng-template>
  `,
  animations: [
    trigger('children', [
      state('void', style({
        height: '0px'
      })),
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        height: '*',
        'z-index': 100
      })),
      state('hidden', style({
        height: '0px',
        'z-index': '*'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('void => visibleAnimated, visibleAnimated => void',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppSubMenuComponent {

  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _reset: boolean;

  _parentActive: boolean;

  activeIndex: number;

  constructor(public app: AppComponent, public router: Router, public location: Location, public appMenu: AppMenuComponent) {
  }

  itemClick(event: Event, item: MenuItem, index: number) {
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
      event.preventDefault();
    }

    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    if (item.routerLink || item.items || item.command || item.url) {
      this.activeIndex = (this.activeIndex as number === index) ? -1 : index;
    }

    // execute command
    if (item.command) {
      item.command({originalEvent: event, item: item});
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      setTimeout(() => {
        this.appMenu.layoutMenuScrollerViewChild.moveBar();
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.app.isMobile()) {
        this.app.sidebarActive = false;
        this.app.mobileMenuActive = false;
      }

      if (this.app.isHorizontal()) {
        this.app.resetMenu = true;
      } else {
        this.app.resetMenu = false;
      }

      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  onMouseEnter(index: number) {
    if (this.root && this.app.menuHoverActive && this.app.isHorizontal()
      && !this.app.isMobile() && !this.app.isTablet()) {
      this.activeIndex = index;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && (this.app.isHorizontal() || this.app.isOverlay())) {
      this.activeIndex = null;
    }
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
}
