import {Component, EventEmitter, Input, KeyValueDiffer, OnInit, Output, ViewChild} from '@angular/core';
import {BillOfEntry} from '../models/billOfEntries';
import {BillOfEntryService} from '../services/bill-of-entry.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {LazyLoadEvent, ConfirmationService} from 'primeng/api';
import {AuthorisationService} from '../../../subjects/authorisation-behaviour-subject';
import {FileType} from '../models/enumerations';
import {ClearingFile} from '../models/clearingFile';
import {ImporterService} from '../services/importer.service';
import {AgentService} from '../services/agent.service';
import {BusinessEntity} from '../../digi-business-entity/models/business-entity';
import {BusinessEntityService} from '../../digi-business-entity/services/business-entity.service';
import {NotificationService} from '../services/notification.service';
import {SendEdiToSarsService} from '../services/send-edi-to-sars.service';
import {Invoice} from '../models/financials';
import {InvoiceService} from '../services/invoice.service';
import {ClearingFileService} from '../services/clearing-file.service';
import {OverlayPanel} from 'primeng/primeng';

enum TransportMethods {
  SC = 'sea', SE = 'sea', AC = 'air', AE = 'air', RC = 'road', RE = 'road', TE = 'rail'
}

@Component({
  selector: 'digi-sars-dashboard',
  templateUrl: './sars-dashboard.component.html',
  styleUrls: ['./sars-dashboard.component.scss'],
  providers: [ConfirmationService]
})
export class SarsDashboardComponent implements OnInit {
  @Input() transportMethod: string;
  @Output() selectedClearingFile: EventEmitter<any> = new EventEmitter();
  @ViewChild('correctionList') correctionList: OverlayPanel;
  @Input() businessEntity: BusinessEntity;
  filteredBusinessEntities = [];
  clearingFile: ClearingFile;
  billsOfEntry: BillOfEntry[];
  selectedBoes: BillOfEntry[];
  totalRecords: any;
  loading: boolean;
  lrnNo: string;
  mrnNo: string;
  caseNo: string;
  supplierInvNo: string;
  clearingFileId: string;
  selectedClientInvoice: Invoice;
  filteredInvoices: Invoice[];
  fromDate: string;
  toDate: string;
  cols: any[];
  rcgCols: any[];
  indicator: FileType;
  displaySearchModal: boolean;
  rcgMode: boolean;
  filterRejections: boolean;
  mawbNo: string;
  declarantName: string;
  foreignDeclarantName: string;
  containerNo: string;
  fileNo: string;
  referenceNo: string;
  mawbFilter: string;
  filteredClearingFiles: any[];
  listClearingFiles: any[];
  private branchCode: any;

  constructor(
    private localAgentService: AgentService,
    private importerService: ImporterService,
    private billOfEntryService: BillOfEntryService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: NotificationService,
    private sendEdiToSarsService: SendEdiToSarsService,
    private authorisationService: AuthorisationService,
    private confirmationService: ConfirmationService,
    private businessEntityService: BusinessEntityService,
    private invoiceService: InvoiceService,
    private clearingFileService: ClearingFileService,
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.indicator = this.route.snapshot.paramMap.get('indicator') as FileType;
        this.rcgMode = false;
        this.loadBillOfEntries();
      }
    });
  }

  ngOnInit() {
    this.cols = [
      {field: 'ediMessage.clearingFileNumber', header: 'File Number', width: '8%'},
      {field: 'ediMessage.sequenceNumber', header: 'Seq', width: '3%'},
      {field: 'ediMessage.lrn', header: 'LRN', width: '10%'},
      {field: '', header: 'MRN', width: '7%'},
      {field: '', header: 'CPC', width: '3%'},
      {field: 'ediMessage.dateEdiSubmitted', header: 'Sent On', width: '6%'},
      {field: '', header: 'MDN', width: '3%'},
      {field: '', header: 'CONTRL Recv On', width: '6%'},
      {field: '', header: 'CONTRL', width: '3%'},
      {field: '', header: 'CUSRES Recv On', width: '6%'},
      {field: '', header: 'CUSRES', width: '12%'},
    ];
    this.rcgCols = [
          {field: 'ediMessage.clearingFileNumber', header: 'File Number', width: '10%'},
          {field: 'ediMessage.sequenceNumber', header: 'Seq', width: '3%'},
          {field: 'ediMessage.lrn', header: 'LRN', width: '10%'},
          {field: '', header: 'MRN', width: '7%'},
          {field: '', header: 'CPC', width: '3%'},
          {field: 'ediMessage.dateEdiSubmitted', header: 'Sent On', width: '10%'},
          {field: '', header: 'MDN', width: '4%'},
          {field: '', header: 'CONTRL Recv On', width: '6%'},
          {field: '', header: 'CONTRL', width: '4%'},
          {field: '', header: 'CUSRES Recv On', width: '6%'},
          {field: '', header: 'CUSRES', width: '12%'},
        ];
    this.rcgMode = false;
    this.mawbFilter = null;
    this.selectedBoes = [];
  }

  onLazyLoad(event: LazyLoadEvent, indicator) {
    this.authorisationService.getLoggedInUser().subscribe(user => {
      if (user) {
        this.loading = true;
        const pageNumber = event.first / event.rows;
        if (user && user.attributes && user.attributes['branch']) {
          this.branchCode = user.attributes['branch'][0];
        }

        if (this.rcgMode && indicator === 'imports') {
          if (this.mawbFilter && this.mawbFilter !== '') {
            this.billOfEntryService.findAllRcgEdiMessages(pageNumber, event.rows, this.branchCode, this.mawbFilter, this.filterRejections)
              .subscribe(data => {
                  this.billsOfEntry = data._embedded.importBillOfEntries;
                  this.totalRecords = data.page.totalElements;
                  this.loading = false;
                },
                err => {
                  this.loading = false;
                });
          } else {
            this.billsOfEntry = [];
            this.loading = false;
          }
        } else {
          this.billOfEntryService.findAllEdiMessages(pageNumber, event.rows, indicator, this.branchCode)
            .subscribe(data => {
              if (indicator === 'imports') {
                this.billsOfEntry = data._embedded.importBillOfEntries;
              } else if (indicator === 'exports') {
                this.billsOfEntry = data._embedded.exportBillOfEntries;
              } else {
                this.billsOfEntry = data._embedded.exBondBillOfEntries;
              }
              this.totalRecords = data.page.totalElements;
              this.loading = false;
            },
            err => {
              this.loading = false;
            });
        }
      }
    });
  }

  canSubmitToSars() {
    return this.mawbFilter && this.rcgMode && this.billsOfEntry && this.billsOfEntry.length > 0;
  }

  loadBillOfEntries() {
    this.selectedBoes = [];
    this.onLazyLoad({first: 0, rows: 10}, this.indicator);
  }

  controlStatus(billOfEntry: BillOfEntry) {
    if (billOfEntry) {
      if (billOfEntry.contrl) {
        if (billOfEntry.contrl.messageStatus === 'ACKNOWLEDGED' && billOfEntry.contrl.interchangeStatus === 'ACKNOWLEDGED') {
          return true;
        }
      }
    }
    return false;
  }

  mdnResponseStatus(billOfEntry: BillOfEntry) {
    if (billOfEntry) {
      if (billOfEntry.mdnResponse) {
        if (!billOfEntry.mdnResponse.errorMessage) {
          return true;
        }
      }
    }
    return false;
  }

  loadClearingFile(fileNumber: string, sequenceNumber: number) {
    const transportCode = fileNumber.substring(0, 2);
    let path;
    if (this.indicator === 'imports') {
      path = TransportMethods[transportCode];
    } else if (this.indicator === 'exports') {
      path = `export/${TransportMethods[transportCode]}`;
    } else {
      path = `exbond`;
    }
    this.router.navigate([path, 'register', fileNumber, sequenceNumber]);
  }


  getStatus(ediMessage: any) {
    if (ediMessage && ediMessage.cusres) {
      if (ediMessage.cusres.customsStatus === 'DETAIN_OTHER_OGA_OTHER_GOVERNMENT_AGENCY' || ediMessage.cusres.customsStatus === 'CUSTOMS_STOP_AWAITING_OGA_OUTCOME' && ediMessage.cusres.customsStatusError) {
        return ediMessage.cusres.customsStatusError;
      } else {
        return ediMessage.cusres.customsStatus;
      }
    }
  }

  openSearchCriteriaModal() {
    this.displaySearchModal = true;
  }

  closeModal() {
    this.displaySearchModal = false;
  }

  searchBoes() {
    this.loading = true;
    this.billOfEntryService
      .findBoesByParameters
      (
        this.mawbNo,
        this.declarantName,
        this.foreignDeclarantName,
        this.containerNo,
        this.lrnNo,
        this.fileNo,
        this.referenceNo,
        this.mrnNo,
        this.caseNo,
        this.supplierInvNo,
        this.clearingFileId,
        this.fromDate,
        this.toDate,
        this.branchCode,
        this.indicator
      )
      .subscribe(data => {
          this.billsOfEntry = data;
          this.loading = false;
          this.displaySearchModal = false;
        },
        err => {
          this.loading = false;
          this.displaySearchModal = false;
        });
  }

  searchBusinessEntity(event) {
    this.businessEntityService.findBusinessEntityStartsWith(event.query).subscribe(data => {
      this.filteredBusinessEntities = data;
    });
  }

  searchImporterEntityCode(event) {
    this.businessEntityService.findBusinessEntityImporterCode(event.query).subscribe(data => {
      this.filteredBusinessEntities = data;
    });
  }

  loadRcgBoes(event) {
    this.rcgMode = event;
    if (!this.rcgMode) {
      this.mawbFilter = null;
    }
    this.loadBillOfEntries();
  }

  loadRcgRejectionBoes(event) {
    this.filterRejections = event;
    this.loadBillOfEntries();
  }

  submitBoesToSars() {
    if (this.selectedBoes && this.selectedBoes.length > 0) {
      this.confirmationService.confirm({
            message: 'Are you sure that you want to send EDI for selected files?',
            accept: () => {
              this.messageService.successNotify('Sending EDI...');
              this.sendEdiToSarsService
                .sendSelectedRcgBillsOfEntry(this.selectedBoes)
                .subscribe(data => {
                    this.messageService.successNotify('EDI sent');
                    this.loadBillOfEntries();
                  },
                  _ => this.loadBillOfEntries());
            }
          });
    } else {
      this.messageService.errorNotify('No files selected for EDI!');
    }
  }

  selectedImporterOrExporterBusinessEntity(value) {
    this.declarantName = value.name;
  }

  selectedSupplierBusinessEntity(value) {
    this.foreignDeclarantName = value.name;
  }

  search(event): void {
    this.invoiceService.findAllInvoicesStartingWith(event.query, this.indicator).subscribe((invoices: Invoice[]) => {
        this.filteredInvoices = invoices;
      });
  }

  select(value: Invoice): void {
    this.invoiceService.findClearingFileByInvoiceSelfRefLink(value).subscribe( (clearingFile: ClearingFile) => {
      this.clearingFileId = clearingFile.id;
    });
  }
  selectCorrection(value) {
    this.clearingFileService.selectClearingFile(value);
    this.correctionList.hide();
  }
  selectInitial(value) {
    if (value.type !== 'Correction') {
      this.clearingFileService.selectClearingFile(value);
    }
  }

  clearAll(): void {
    this.selectedClientInvoice = undefined;
  }
}
